import React, { createContext, useContext } from 'react';

interface IOrderReturnsLoaderContext {
    orderReturnsPageLoading: boolean;
    setOrderReturnsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IOrderReturnsLoaderContext | undefined>(undefined);

export const useOrderReturnsLoader = (
): IOrderReturnsLoaderContext => useContext(LoaderContext) as IOrderReturnsLoaderContext;
