/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useReactiveVar,
} from '@apollo/client';
import { IReturn } from 'graphql/rma/rmaInterfaces';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { useOrderReturnsLoader } from './order-returns-loader';
import { currentOrderVar } from '../../layouts/sales-layout/sales-layout-state';

interface IInitializeOrderReturnsPageProps {
    children: React.ReactNode;
}

export const orderReturnsVar: ReactiveVar<IReturn[]|undefined> =
    makeVar<IReturn[]|undefined>(undefined);

const updateOrderReturns = (orderReturns?: IReturn[]|undefined): void => {
    orderReturnsVar(orderReturns);
};

export const resetStoredData = (): void => {
    updateOrderReturns();
};

export const InitializeOrderReturnsPage: React.FC<IInitializeOrderReturnsPageProps> = (
    {
        children,
    },
) => {
    const { orderReturnsPageLoading, setOrderReturnsPageLoading } = useOrderReturnsLoader();

    const currentOrder = useReactiveVar(currentOrderVar);

    const processOrderReturnsData = (currentOrder: ICustomerOrder) => {
        const { returns } = currentOrder;
        const { items } = returns;
        updateOrderReturns(items);
    };

    const loadOrderReturnsPageData = async (currentOrder: ICustomerOrder) => {
        processOrderReturnsData(currentOrder);
    };

    useEffect(() => {
        resetStoredData();
        if (!currentOrder) {
            return;
        }

        if (!orderReturnsPageLoading) {
            setOrderReturnsPageLoading(true);
        }

        loadOrderReturnsPageData(currentOrder).finally(() => {
            setOrderReturnsPageLoading(false);
        });
    }, [currentOrder]);

    return (<>{children}</>);
};
