import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IReturn } from 'graphql/rma/rmaInterfaces';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { OrderReturns } from 'ui/component/rma/order-returns';
import {
    salesLayoutConfigVar,
    currentOrderVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { PageContent } from '../../layouts/components/page-content';
import {
    InitializeOrderReturnsPage,
    orderReturnsVar,
} from './order-returns-state';
import { LoaderContext } from './order-returns-loader';
import styles from './style.css';

export const OrderReturnsPageContent: React.FC = () => {
    const [orderReturnsPageLoading, setOrderReturnsPageLoading] = useState(false);
    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentOrder: ICustomerOrder|undefined = useReactiveVar(currentOrderVar);
    const orderReturns: IReturn[]|undefined = useReactiveVar(orderReturnsVar);
    const t = usePhraseTranslater();

    return (
        <LoaderContext.Provider value={{ orderReturnsPageLoading, setOrderReturnsPageLoading }}>
            <InitializeOrderReturnsPage>
                <PageContent
                    pageTitle={t('Your Returns')}
                    pageLoading={orderReturnsPageLoading}
                    pageContentClassName={styles.orderReturnsUi}
                >
                    <OrderReturns config={config} order={currentOrder} orderReturns={orderReturns} />
                </PageContent>
            </InitializeOrderReturnsPage>
        </LoaderContext.Provider>
    );
};
