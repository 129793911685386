import React from 'react';
import { IReturn } from 'graphql/rma/rmaInterfaces';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { SalesDetailsLayout } from 'ui/component/sales-components/sales-details-layout';
import { OrderReturn } from './order-return';
import styles from './style.css';

interface IOrderReturnsProps {
    config: ISalesLayoutConfig;
    order?: ICustomerOrder;
    orderReturns?: IReturn[];
}

export const OrderReturns: React.FC<IOrderReturnsProps> = (
    {
        config,
        order,
        orderReturns,
    },
) => (
    <SalesDetailsLayout
        order={order}
        itemsWrapperClassName={styles.returnsWrapper}
        orderNumberAsLink
        layoutPage="returns"
    >
        {orderReturns && orderReturns.map(
            (orderReturn: IReturn) => (
                <OrderReturn config={config} orderReturn={orderReturn} />
            ),
        )}
    </SalesDetailsLayout>
);
