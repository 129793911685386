import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IReturn } from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { HeaderInfo } from 'ui/component/sales-components/header-info';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

interface IOrderReturnProps {
    config: ISalesLayoutConfig;
    orderReturn: IReturn;
}

export const OrderReturn: React.FC<IOrderReturnProps> = (
    {
        orderReturn,
    },
) => {
    const t = usePhraseTranslater();
    const {
        uid,
        number,
        dateFormatted,
        customer,
        statusLabel,
        viewUrl,
    } = orderReturn;

    const {
        firstname,
        lastname,
    } = customer;

    const shipFromName = `${firstname} ${lastname}`;

    return (
        <div className={styles.orderReturn} data-id={uid}>
            <HeaderInfo
                title={t('Return #')}
                className={styles.rmaNumber}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                {number}
            </HeaderInfo>
            <HeaderInfo
                title={t('Date')}
                className={styles.rmaDate}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                {dateFormatted}
            </HeaderInfo>
            <HeaderInfo
                title={t('Ship From')}
                className={styles.rmaShipFrom}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                {shipFromName}
            </HeaderInfo>
            <HeaderInfo
                title={t('Return Status')}
                className={styles.rmaStatus}
                titleClassName={styles.title}
                textClassName={styles.text}
            >
                {statusLabel}
            </HeaderInfo>
            <HeaderInfo className={styles.viewLink} titleClassName={styles.title} textClassName={styles.text}>
                <Link
                    label={t('View Return')}
                    href={viewUrl}
                    opacityOnHover
                />
            </HeaderInfo>
        </div>
    );
};
